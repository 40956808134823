<template>
  <div class="merch-card">
    <!-- 搜索框 -->
    <el-form ref="queryForm" :model="queryParams" :inline="true">
      <el-form-item>
        <el-select
          clearable
          v-model="queryParams.businessId"
          filterable
          remote
          placeholder="输入供应商名称/编号选择查询"
          :remote-method="remoteMethod"
          :loading="searchLoading"
        >
          <el-option
            v-for="item in businessList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-date-picker
          clearable
          v-model="datelist"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item prop="requisitionCode">
        <el-input
          v-model="queryParams.requisitionCode"
          placeholder="申请单号"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item prop="orderId">
        <el-input
          v-model="queryParams.orderId"
          placeholder="商品订单号"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item prop="customerInfo">
        <el-input
          v-model="queryParams.customerInfo"
          placeholder="单位名称/客户编号"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item prop="payType">
        <el-select clearable v-model="queryParams.processStatus" placeholder="请选择处理状态">
          <el-option
            v-for="item in selData.ProcessingStatus"
            :key="item.id"
            :label="item.Name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
    </el-form>

    <!-- 表格数据 -->
    <div class="dataForm">
      <el-table
        v-loading="loading"
        :data="OrderqueryList"
        :header-cell-style="{ background: '#F2F3F5', color: '#383B46' }"
        border
        style="width: 100%"
        max-height="550"
        ref="tableder"
        header-align="center"
      >
        <el-table-column label="创建时间" width="180" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.createTime }}</div>
          </template>
        </el-table-column>
        <el-table-column label="申请单号" width="180" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.requisitionCode }}</div>
          </template>
        </el-table-column>
        <el-table-column label="客户单位名称" width="180" align="center">
          <template slot-scope="scope">
            <!-- style="color: #06B7AE;" -->
            <div >{{ scope.row.enterprise }}</div>
          </template>
        </el-table-column>
        <el-table-column label="所属供应商" width="180" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.businessName || '--'}}</div>
          </template>
        </el-table-column>
        <el-table-column label="申请采购品种数量" width="180" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.goodsNum || '--'}}</div>
          </template>
        </el-table-column>
        <el-table-column label="收货人|收货电话" width="180" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.receivingAddress.consignee }} | {{ scope.row.receivingAddress.mobile }} </div>
          </template>
        </el-table-column>
        <el-table-column label="收货地址信息" width="180" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.allAddress || '--'}}</div>
          </template>
        </el-table-column>
        <el-table-column label="合纵校验状态" width="180" align="center">
          <template slot-scope="scope">
            <div>{{ getStatusData(scope.row.insideStatus,1) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="处理结果状态" width="180" align="center">
          <template slot-scope="scope">
            <div :style="scope.row.processStatus == 3 || scope.row.processStatus == 4?'color:#FF0000;':''">{{ getStatusData(scope.row.processStatus,0) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="关联订单号" width="180" align="center">
          <template slot-scope="scope">
            <div style="color: #06B7AE;cursor: pointer;" @click="onToOrder(item)" v-for="(item,index) in scope.row.orderIds" :key="index">{{ item }}</div>
          </template>
        </el-table-column>
        <el-table-column label="更新时间" width="180" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.updateTime || '--'}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" fixed="right" align="center" width="140">
          <template slot-scope="{row}">
            <el-button
                type="text"
                size="small"
                @click="detailsing(row)"
                >详情</el-button
              >
          </template>
          
        </el-table-column>


        
      </el-table>
    </div>
    <div class="merch-page">
      <el-pagination
        :current-page="queryParams.page"
        background
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const getOrder = createNamespacedHelpers("Order");
const { mapActions } = createNamespacedHelpers("Order");
export default {
  name: "requestNotes",
  watch: {
    "queryParams.date": {
      handler(oldVal) {
        this.datelist = [this.queryParams.startTime, this.queryParams.endTime];
      },
      deep: true
    },
    datelist(v) {
      if (v == null) {
        this.queryParams.startTime = "";
        this.queryParams.endTime = "";
      } else {
        this.queryParams.startTime = v[0];
        this.queryParams.endTime = v[1];
      }
    }
  },
  data() {
    return {
      // 时间选择配置项
      pickerOptions: {
        shortcuts: []
      },
      loading: false,
      OrderqueryList: [],
      total: 1,
      // 搜索栏
      queryParams: {
        page: 1,
        perPage: 10,
        businessId: "", // 供应商id
        startTime: "", //开始时间
        endTime: "", //结束时间
        requisitionCode: "", //申请单号
        orderId: "", //订单编号
        customerInfo: "", //单位名称/客户编号
        processStatus: "" //1.待处理 2.处理中 3.部分成功 4.全部失败 5.全部成功
      },
      searchLoading: false,
      businessList: [], //供应商列表
      datelist: [],
      selData: require("../../assets/json/orderSel.json")
    };
  },
  computed: {
    ...commonIndex.mapState(["detailsSeach"])
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.queryParams = this.detailsSeach[router];
      this.datelist=this.detailsSeach[router].datelist
      if( this.detailsSeach[router].businessId ){
        this.remoteMethod(this.detailsSeach[router].businessId,'search')
      }
    }else{
      this.$notify({
        title: '提示',
        message: '请选择供应商查询自动请货申请单',
        type: 'warning'
      });
    }
    this.getTime();
  },
  methods: {
    ...mapActions(["getbasistime"]),
    ...getOrder.mapActions(["orderApplicationList","businessSearch"]),
    ...commonIndex.mapMutations(["changeSeach"]),

    onToOrder(item){
      this.$router.push({
        path: "/detailsedes",
        query: { id: item },
      });
    },
    detailsing(row){
      this.$router.push({
        path: "/requestNotesDetails",
        query: { id: row.id,businessId: this.queryParams.businessId},
      });
    },
    getStatusData(status,num){
      let arr = ['待处理','处理中','部分成功','全部失败','全部成功'];
      let arr1 = ['处理中','正常','异常'];
      let str = ''
      if( num === 0 ){
        str = arr[Number(status)-1];
      }else{
        str = arr1[Number(status)-1];
      }
      return str;
    },

    async remoteMethod(query,type) {
      if (query !== "") {
        this.searchLoading = true;
        let res = await this.businessSearch({ name: query });
        if (res.code != "200") return this.$message.error(res.message);
        this.searchLoading = false;
        this.businessList = res.data.map(item => {
          item.name = item.id + " / " + item.name;
          return item;
        });
        if( type == 'search' ){
          this.getList()
        }
      } else {
        this.businessList = [];
      }
    },
    /** 查询列表数据 */
    async getList() {
      var from = { ...this.queryParams };
      if (
        this.queryParams.startTime != "" &&
        this.queryParams.startTime != undefined
      ) {
        from.startTime = this.queryParams.startTime + " 00:00:00";
        from.endTime = this.queryParams.endTime + " 23:59:59";
      }
      let res = await this.orderApplicationList(from);
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.queryParams };
      save.datelist=this.datelist
      save.router = router;
      this.changeSeach(save);
      this.OrderqueryList = res.data.data.map(item => {
        if( item.receivingAddress ){
          item.allAddress = item.receivingAddress.province + item.receivingAddress.city + item.receivingAddress.district + item.receivingAddress.address
        }
        return item
      });
      this.total = res.data.total;
    },
    // 分页
    handleCurrentChange(item) {
      this.queryParams.page = item;
      this.getList();
    },
    // 格式化时间
    timeFormat(date) {
      if (!date || typeof date === "string") {
        this.error("参数异常，请检查...");
      }
      var y = date.getFullYear(); //年
      var m = date.getMonth() + 1; //月
      var d = date.getDate(); //日

      return y + "-" + m + "-" + d;
    },
    //获取这周的周一
    getFirstDayOfWeek() {
      var date = new Date();
      var weekday = date.getDay() || 7; //获取星期几,getDay()返回值是 0（周日） 到 6（周六） 之间的一个整数。0||7为7，即weekday的值为1-7
      date.setDate(date.getDate() - weekday + 1); //往前算（weekday-1）天，年份、月份会自动变化
      date = this.timeFormat(date);
      return date;
    },
    // 获取选择时间
    async getTime() {
      let firstDayOfWeek = this.getFirstDayOfWeek();
      let data = await this.getbasistime();
      if (data.code == 200) {
        this.pickerOptions.shortcuts = [
          {
            text: "今日",
            onClick(picker) {
              picker.$emit("pick", [data.data.today, data.data.today]);
            }
          },
          {
            text: "昨日",
            onClick(picker) {
              picker.$emit("pick", [data.data.yesterday, data.data.yesterday]);
            }
          },
          {
            text: "本周",
            onClick(picker) {
              picker.$emit("pick", [firstDayOfWeek, data.data.today]);
            }
          },
          {
            text: "当月",
            onClick(picker) {
              picker.$emit("pick", data.data.month);
            }
          },
          {
            text: "上月",
            onClick(picker) {
              picker.$emit("pick", data.data.last_month);
            }
          }
        ];
      }
    },
    /** 日期搜索*/
    querydate(date) {
      var day = new Date();
      switch (date) {
        case "today":
          this.queryParams.startTime = day.format("yyyy-MM-dd 00:00:00");
          this.queryParams.endTime = day.format("yyyy-MM-dd 23:59:59");
          break;
        case "yesterday":
          this.queryParams.startTime = new Date(
            new Date().setDate(new Date().getDate() - 1)
          ).format("yyyy-MM-dd 00:00:00");
          this.queryParams.endTime = new Date(
            new Date().setDate(new Date().getDate() - 1)
          ).format("yyyy-MM-dd 23:59:59");
          break;
        case "week":
          var now = new Date();
          var oneDayTime = 24 * 60 * 60 * 1000;
          var nowTime = now.getTime();
          var dayed = now.getDay();
          var SundayTime = nowTime + (7 - dayed) * oneDayTime;
          var sunday = new Date(SundayTime);
          var nowDayOfWeek = now.getDay() == 0 ? 6 : now.getDay() - 1;
          this.queryParams.startTime =
            this.getDateStr(
              new Date(now.getTime() - nowDayOfWeek * 24 * 60 * 60 * 1000)
            ) + " 00:00:00";
          this.queryParams.endTime = this.getDateStr(sunday) + " 23:59:59";
          break;
        case "month":
          this.queryParams.startTime = new Date(new Date().setDate(1)).format(
            "yyyy-MM-dd 00:00:00"
          );
          this.queryParams.endTime = new Date(
            new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)
          ).format("yyyy-MM-dd 23:59:59");
          break;
        case "lastMonth":
          this.queryParams.startTime = new Date(
            new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)
          ).format("yyyy-MM-dd 00:00:00");
          this.queryParams.endTime = new Date(new Date().setDate(0)).format(
            "yyyy-MM-dd 23:59:59"
          );
          break;
      }
      this.queryParams.date = date;
      this.queryParams.page = 1;
      this.getList();
    },
    /** 搜索按键操作 */
    handleQuery() {
      if( this.queryParams.businessId == '' ) return this.$message.error('请选择供应商')
      this.queryParams.page = 1;
      this.OrderqueryList = [];
      this.getList();
    }
  }
};
</script>
<style lang="scss" scoped>
.merch-card {
  width: 100%;
  min-width: 1000px;
  max-height: 100%;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  background: #ffffff;
}
.defaultStyle {
  padding: 10px 20px;
  text-align: center;
  line-height: 100%;
  font-size: 14px;
  cursor: pointer;
  color: rgba(28, 31, 35, 0.6);
}
.opp {
  color: #06b7ae;
  border-radius: 3px;
  background-color: #f2f2f2;
}
.el-form-item {
  margin-bottom: 5px;
}
.dataForm{
  margin-top: 20px;
}
</style>
